import { useContext, useEffect } from 'react';
import { ACTIONS } from '@utils/enums.js';
import { appState } from '@utils/store';
import useMultiplier from '@hooks/socket/useMultiplier';
import LButton from '../../../../../../components/button';
import { BetContext } from '../../../../../../context/bet';
import { FreeBetContext } from '../../../../../../context/free-bet';
import { buttonVariantsMap } from '../../helpers';

const variant = buttonVariantsMap[ACTIONS.CASH_OUT];

const CashOut = ({ onClick }) => {
  const { maxWin, afterDot, minAutoCashout, currency } = appState.provider;
  const { betValue, autoCashOut, loading, setLoading } = useContext(BetContext);
  const { isFreeBet, suggestions } = useContext(FreeBetContext);

  const multiplier = useMultiplier();

  const suggestion = isFreeBet && suggestions.find(({ betNominal }) => betNominal === betValue);
  const disabled = suggestion ? suggestion.minMultiplier > multiplier : minAutoCashout > multiplier;

  useEffect(() => {
    if (!autoCashOut) return;
    setLoading(autoCashOut <= multiplier);
  }, [autoCashOut, multiplier, setLoading]);

  const coefficient = autoCashOut ? Math.min(multiplier, autoCashOut) : multiplier;

  return (
    <LButton disabled={disabled} loading={loading} variant={variant} onClick={onClick}>
      {Math.min(coefficient * betValue, maxWin).toFixed(afterDot)} {currency}
    </LButton>
  );
};

export default CashOut;
